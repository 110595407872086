@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
p,a, li{
  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
  
}
li{
line-height: 40px;
list-style-type: none;
}
input:focus, textarea:focus, select:focus,button{
  outline: none;
}
h2,h3{
  font-size: 20px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}
.header{
  display: flex;
  background-color: #fff;
  padding: 10px 94px;
  justify-content: space-between;
  position: sticky;
    top: 0;
    z-index:99999;
    box-shadow: 0 0 10px #ccc;
}
.logo{
  font-size: 22px;
  color: blueviolet;

}
.logo img{
  width: 150px;
}
.banner{
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner img{
  width: 100%;
  height: 100%;
}
.banner a{
  width: 100%;
}
.menu{
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu ul{
 display: flex;
 margin: 0;
}
.menu ul li{
list-style-type: none;
padding: 0 20px;
transition: 0.3s linear;
}
.menu a{
  text-decoration: none;
  color: #000;
  padding: 10px;
  font-size:13px;
  text-transform: uppercase;
}

.menu a:hover{
  color:#e4001c;
  border-radius: 4px;
}
.mobile_hides li:nth-child(1) {
  font-size: 14px;
  line-height: 32px;
}

.mobilehide {
  display: block!important;
  opacity: 1!important;
  transition: 1s all linear!important;
}

.mobilehides {
  display: block!important;
  opacity: 1.7!important;
  transition: 1s all linear!important;
}

.follow{
  display: flex;
  align-items: center;
  justify-content: center;
}
.follow ul{
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}
.follow li{
  list-style-type: none;
  padding: 0 10px;
  transition: 0.3s linear;
}
.follow li:nth-child(1){
  transform: scale(1)!important;
}
.follow li:hover {
  transform: scale(1.2);
  transition: 0.3s linear;
}

.follow a{
  color: #333;
  text-decoration: none;
  transition: all 0.1s linear;
}

.follow img{
  width:24px;
}
.follow .login{
  width: 90px;
}

.keypoints{
  margin: 130px 0;
  display: flex;
  justify-content: space-between;
 
}
.point{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.point h2{
  font-size: 16px;
  padding-bottom: 10px;
  text-transform: uppercase;
  color: #e4001c;
}
.point p{
  text-align: center;
}
.point a{
  text-transform: uppercase;
  text-decoration: none;
  color: red;
  font-size: 14px;
  font-weight: 600;
  transition: 0.2s linear;
}
.point a:hover{
background-color: #e4001c;
color: #fff;
padding: 5px 10px;
transition: 0.2s linear;
border-radius: 4px;
}

.point:nth-child(2){
  padding:0 30px;
}

.services{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 150px 0 40px 0;
}
.service{
  display: flex;
  width: 33.3%;
  margin-bottom: 90px;
  padding: 0 25px;
}
.service_icon{
  padding: 0 20px;
}
.service_content{
  display: flex;
  flex-direction: column;
}
.service_content h3{
  text-transform: uppercase;
    color: #e4001c;
    font-size: 16px;
    font-weight: bold;
}

.template{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F7F7F7;
  padding-top: 80px;
}
.template_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}
.template_content h2{
  font-size: 16px;
  text-transform: uppercase;
  color: #e4001c;
}
.template_content p{
  padding-top: 20px;
  width: 60%;
  text-align: center;
}
.templates_portfolio{
  display: flex;
  flex-wrap: wrap;
}
.price {
  font-family: 'Kaushan Script', cursive;
  font-size: 24px;
  background-color: #fff;
  padding: 10px 40px!important;
  box-shadow: 0 0 5px #efefef;
  margin: 0;
  width: 100%!important;
}

.price span{
  color: #e4001c;
}
.temp{
  width: 283px;
  height: 400px;
  background-color: #fff;
  border: solid 1px #333;
  position: relative;
  margin: 1px;
}
.temp img{
  width: 100%;
  height: 100%;
}




.tabs{
  display: flex;
  justify-content: center;
  align-items: center;
  background:url('../src/images/towel.jpg')no-repeat;
  background-size:cover;
 
}
.tabs_left_image{
  width: 45%;
  height: 500px;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.screen_banner{
  width:400px;
  height:auto;
  background-color: #fff;
  border-radius: 8px;
  opacity: 0.9;
  margin-top: 60px;
}
.screen_banner img{
  width: 100%;
}
.tabs_right_content{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  padding:160px 0;
}
.accordion{
  width: 600px;
}
.accordion-body{
  color: #666;
  font-family: 'Quicksand', sans-serif;
}

.accordion-button{
  background-color: #F7F7F7;
  font-weight: bold;
}

.accordion-button:not(.collapsed){
  color: #000;
  font-weight: bold;
  background-color: #F7F7F7;
    font-size: 16px;
}
.accordion-body span{
  color: #e4001c;
}
.accordion-button{
  outline: none;
}

.about{
 padding: 0 94px;
  background-color: #F7F7F7;
  display: flex;
  justify-content: space-around;
  max-width: 1520px;
  margin: 0 auto;
}
.about_left{
display: flex;
justify-content: center;
flex-direction: column;
}
.about_left h3{
  color: #333;
  font-size: 24px;
  padding-bottom: 15px;
}
.about_left h3 span{
  color: #e4001c;
}
.about_left p{
  color: #333;
  font-size: 16px;
  padding-right: 100px;
  line-height: 28px;
}
.about_left a{
  background-color: #e4001c;
  text-align: center;
  color: #fff;
  width: 150px;
  text-decoration: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
}
.growing{
  padding: 0 94px;
  background-color: #F7F7F7;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  max-width: 1520px;
  margin:50px 0;
}
.growing_left{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  }
.growing_right{
  text-align: center;
}
.growing_left a{
  background-color: #e4001c;
  text-align: center;
  color: #fff;
  width: 150px;
  text-decoration: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 20px;
}
.growing_left h3{
  color: #e4001c;
  font-size: 38px;
  padding-bottom: 15px;
}

.growing_right img{
width:50%;
}

/*Footer*/
.footer{
  display: flex;
  justify-content: space-around;
  background-color: #000;
  padding: 50px 94px;
}
.footer_about{
  width: 60%;
}
.footer_about p{
  color: #fff;
  width: 90%;
}

.footer_about img{
  width: 170px;
  padding: 10px;
  background-color: #fff;
border-radius: 8px;
margin-bottom: 20px;
}
.footer_policies{
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.footer_policies a{
  color: #fff;
  text-decoration: none;
  padding-bottom: 20px;
}

.footer_contact h3{
  padding-bottom: 10px;
  color: #fff;
  font-size: 18px;
}
.footer_contact p, .footer_contact p a{
  color: #fff;
  text-decoration: none;
}
.poweredby {
  background: #efefef;
  padding: 10px 0;
  margin-bottom: 0;
}
.poweredby img{
  width: 100px;
}
.poweredby ul{
 margin: 0;
 padding: 0;
 display: flex;
 justify-content: center;
 align-items: center;
}
.poweredby ul li{
  list-style-type: none;
  padding: 0 20px;
}
.fa-heart, .webs-color{
  color: #e4001c;
}

/* Footer end*/

/*content pages*/
.contentpage{
  background-image: url('./images/content-bg.png');
  background-repeat: no-repeat;
  height:auto; 
  width:auto;
 display: flex;
 flex-direction: column;
 align-items: center;
 background-size: 250px;
 padding: 0 94px;
}
.contentpage > img{
  padding-top: 50px;
  width: 180px;
}
.contentpage .service_icon img{
  width: auto!important;
}
.contentpage > p{
  padding: 50px 0 0 0;
  font-size: 16px;
  line-height:36px;
}

.contact_page span{
  color: #e4001c;
}
.contact_page p{
  padding: 20px 0 10px 0;
}
.contact_page h6{
  padding-bottom:30px;
}
.contact_page h6 span{
  font-weight: bold;
}
.servicepage span{
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: #e4001c;
  margin:0 5px 0 15px;
  display: inline-block;
}
.contentpage ul{
  margin:0 0 50px 0;
  padding-left: 0;
}
.contentpage h4{
  color: #e4001c;
  font-weight: normal;
  font-size: 18px;
  padding-top:20px;
}
.whatsapp{
  display: inline-block;
  bottom: 200px;
}
.gFMxIq{
  bottom: 70px!important;
}
/* contactc css*/
/* The Modal (background) */
.modal {
 
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #8afab9;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  text-align: center;
}
.modal-content a{
  background-color: #333;
  border: none;
}

.modal-content a:hover{
  background-color: #E4001c;
  border: none;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.contact-cols {
  display: flex;
  justify-content: space-between;
}
.contact-details {
  display: flex;
  justify-content:center;
  background-color: #efefef;
  padding: 30px 0;
  border-radius: 6px;
  margin-bottom: 40px;
}
.contact-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}
.contact-wrap p {
  padding: 20px 0 0 0;
  margin-bottom: 0;
}
.contact-wrap i {
  font-size: 30px;
  color: rgb(92, 92, 92);
  border: solid 2px #e4001c;
  padding: 12px;
  border-radius: 100%;
  box-shadow: 4px 2px 8px #666;
}
.contactform{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-image: url('./images/content-bg.png');
  background-repeat: no-repeat;
  height:auto; 
  width:auto;
}
.or{
  text-align: center;
  padding: 20px 0;
}
/* content pages end*/
/*mailer*/
select,textarea{
  border: solid 1px #ccc;
  border-radius: 6px;
  background-color: #fff;
}
.mailer {
  /* background: #efefef; */
  padding: 30px 100px;
  border-radius: 6px;
}
.mailer form{
  display: flex;
  flex-direction: column;
}
.mailer input{
  width:400px;
  height: 46px;
  border-radius: 4px;
  box-shadow: none;
  border: solid 1px #ccc;
  padding-left: 10px;
}
.mailer form label{
  margin-top: 20px;
}
.button-submit{
  background-color: #e4001c;
  color: #fff;
  margin-top: 20px;
  transition: 0.3s all linear;
}
.button-submit:hover{
  background-color: #a70518;
  transition: 0.3s all linear;
}
select{
  height: 46px;
}
/*mailer ends*/


.accordion-button:focus{
  border: none;
  box-shadow: none;
}

/*responsivebanner*/
.responsive_banner{
  width: 100%;
}
/*responsivebanner end*/
/* Packages*/
.packages_main{
  padding: 100px 94px 0 94px;
  max-width: 1440px;
  margin: 0 auto;
}
.packages_main h2{
  font-size: 16px;
    text-transform: uppercase;
    color: #e4001c;
    text-align: center;
    font-weight: bold;
}
.package_wrap{
  display: flex;
  justify-content: space-evenly;
  padding:0;
}
.package_head{
    background-color: #efefef;
    padding: 20px 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
.package_head .packagelogo{
  background: #fff;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 5px #ccc;
}
.package_head p span{
  font-size: 12px;
  color: #e4001c;
}
.package:nth-child(2) .package_head .packagelogo {
  background-color: rgb(107, 169, 128);
}
.package:nth-child(1) .package_head .packagelogo {
  background-color: rgb(91, 185, 240);
}
.package:nth-child(3) .package_head .packagelogo {
  background-color: rgb(222, 174, 17);
}
.package:nth-child(4) .package_head .packagelogo {
  background-color: rgb(118, 136, 242);
}
.package_head .packagelogo i{
  font-size: 20px;
  color: #fff;
}
.package_head p{
 font-weight: bold;
}
.package_head p:nth-child(2){
  font-size: 18px;
}
.package{
  border: solid 1px #ccc;
  border-radius: 6px;
  width: 30%;
  margin: 10px;
  position: relative;
  padding-bottom: 50px;
}
.package>p {
  margin: 0;
  text-align: center;
  color: #e4001c;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.package_price {
  padding: 10px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: solid 1px #ccc;
}
.package_price p{
  margin: 0;
}
.package_price p:nth-child(1){
font-size: 14px;
color: #666;
text-decoration: line-through ;
}
.package_price p:nth-child(2){
  font-size: 18px;
  color: #e4001c;
  font-weight: bold;
  padding-top: 10px;
  }
.package_content{
padding: 20px 0;
}
.package_content ul{
  margin: 0;
  padding: 0 20px;
}
.package_content ul li{
  list-style-type: none;
  line-height: 30px;
}
.package_content ul li i{
  color: rgb(13, 148, 47);
}
.redcross{
  color: red!important;
}
.getstarted_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  position: absolute;
  bottom: 0px;
  width:100%;

}
.getstarted{
  background-color: #333;
  color:#fff;
 width: 170px;
 text-align: center;
 text-decoration: none;
 font-size: 14px;
 padding: 5px;
 border-radius: 4px;
 transition: 0.3s all linear;
 border: solid 1px #333;
 font-weight: bold;
}
.getstarted:hover{
  color: #e4001c;
  background-color: #fff;
  border: solid 1px #e4001c;
  transition: 0.3s all linear;
  font-weight: bold;
}
.countries_currency{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.countries_currency div{
  margin:10px 5px 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: auto;
}
.countries_currency div img{
  width: 100%;
}
/*packages*/
/*DM*/
.dm{
  width:100%;
  height: auto;
  background-color: #efefef;
  padding: 50px 94px;
  margin: 100px 0 0 0;

}
.dm_wrap{
  max-width: 1440px;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  background-color: #fff;
  border-radius: 6px;
}
.dmservice_icon img{
  width:70px;
}
.dm_service, .dm_training{
  background-color: #fff;
  margin: 10px;
  flex-basis: 47%;
}
.dmservice_icon{
margin-bottom: 20px;
}
.dmservice_content a{
  background-color:#e4001c;
  padding:3px 6px;
  border-radius: 6px;
  color: #fff;
  text-decoration: none;
  font-size: 12px;
  margin-left: 20px;
}
.dmtraining a{
  position: relative;
  display: inline-block;
}
.dmtraining a .tooltiptext{
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: -45px;
  left: 0;
  z-index: 1;
}
.dmtraining a:hover .tooltiptext {
  visibility: visible;
}
.dmtraining a:hover{
  cursor:not-allowed;

}
/*#RESPONSIVE*/

@media only screen and (max-width:767px) {
  /* .header{
display: none;
  } */
  .header {
    position: sticky;
    top: 0;
    width: 100%;
    transition: 0.3s linear;
    flex-direction: column;
    padding: 20px 5px;
    align-items: center;
}

.mobile_hide, .mobile_hides{
  display: none;
  opacity: 0;
}

  .mobile-hide{
    display: none;
  }
  span.hamburger img{
    width: auto;
  }
  span.hamburger {
    position: absolute;
    top: 19px;
    right: 25px;
    display: block;
}
  .logo span{
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-left: 40px;
    border-radius: 4px;
  }
  .menu ul{
    flex-direction: initial;
    margin-bottom: 10px;
    padding: 0;
  }
  .menu ul li{
    padding: 0 7px;
  }
  .banner{
height: auto;
  }
  .banner a{
    width: 100%;
  }
  .keypoints{
    margin: 50px 0;
    flex-direction: column;
  }
  .point{
    margin-bottom: 50px;
  }
  .about{
    padding: 0 33px;
    flex-direction: column;
  }
  .about_left{
    align-items: center;
    padding-top: 50px;
  }
  .about_left p{
    padding-right: 0;
  }
  .about_right img{
    width: 100%;
    padding-top: 50px;
  }
  .service{
    width: auto;
    padding: 0;
  }
  .service_icon img{
    width: auto;
  }
  
  .tabs{
    flex-direction: column;
    padding: 0 33px;
      max-width: 1440px;
      margin: 0 auto;
  
  }
  .tabs_left_image{
    height: auto;
    width: 100%;
    display: none;
  }
  .tabs_right_content{
    padding: 50px 0;
    width: 100%;
  }
  .template_content{
    padding: 0 33px 40px 33px;
  }
  .templates_portfolio{
    justify-content: center;
    align-items: center;
  }
  .template_content p{
    width: 100%;
  }
  .services{
    margin: 50px 0;
  }
  .service{
    margin-bottom: 30px;
  }
  .footer{
    padding: 50px 33px;
    flex-wrap: wrap;
  }
  .footer_about,.footer_policies,.footer_contact{
    width: 100%;
  }
  .contentpage > p{
    padding: 10px 33px;
  }
  .contact-wrap p {
    padding: 20px 20px;
  }
  .contact_page p{
    text-align: center;
  }
  .contact_page h3 {
    padding: 0 33px 50px 33px;
    text-align: center;
    line-height: 36px;
}
.contentpage{
  padding: 0 33px;
}
.poweredby {
  margin-bottom: 20px;
}
.mailer{
  padding: 30px 0px;
}
.mailer input{
  width: 100%;
}

.package_wrap{
  flex-wrap: wrap;
}
.package {
  width: 45%;
  margin: 5px;
}
.package_head p span{
  display: none;
}
.package_price{
  padding: 10px;
}
.package_content ul li {
  font-size: 14px;
}
.package_content ul{
  padding: 10px;
}
.dm{
  margin: 0;
  padding: 20px 33px;
}
.dm_wrap{
  flex-direction: column;
}
.modal-content{
  width: 70%;
}
.webbanner{
  display: none;
}
.mobilebanner{
  display: block;
}
} 

@media only screen and (min-width:480px) {
  .menu ul{
    flex-direction: initial!important;
    padding: 0;
  }
  .menu ul li{
    padding: 0;
  }
}
@media only screen and (max-width:480px) {
  .menu a {
    text-decoration: none;
    color: #333;
    padding: 0px;
    font-size: 13px;
 text-transform: capitalize;
    font-weight: 300;
}
.poweredby ul{
  flex-direction: column;
}
.poweredby ul li{
  padding: 0 10px;
  font-size: 12px;
}
.contact-details{
  flex-direction: column;
}
.follow li{
  padding: 0 4px;
}
.follow li:nth-child(1){
  display: none;
}



}
@media only screen and (min-width:768px)and (max-width:980px) {
  .follow li{
    padding: 0 5px;
  }
  .follow img {
    width: 19px;
}
.menu a{
  font-size: 11px;
  padding: 7px;
}
.follow li:nth-child(1){
  display: none;
}
.package{
  margin: 10px;
}
}
@media only screen and (max-width:560px) {
  .growing{
    padding:0 33px;
  }
  .growing_left h3{
    font-size: 21px;
    text-align: center;
  }
  .growing_left h4{
    font-size: 18px;
    text-align: center;
  }
  .growing_right img{
    width: 90%;
  }
}
@media only screen and (min-width:768px) {
  .mobilebanner{
    display: none;
  }
  .header{
    padding: 10px 33px;
      }
      span.hamburger{
        display: none;
      }
      .mobile_hide,.mobile_hides{
        display: flex!important;
        flex-direction: initial!important;
      }
      .mobile_hide,.mobile_hides {
    display: flex;
    flex-direction: inherit;
}
      .banner{
        height: auto;
      }
      .about{
        padding:30px 33px;
      }
      .about_left{
        width: 50%;
      }
      .about_right img{
        width: 100%;
      }
      .keypoints{
        margin:70px 0;
      }
      .tabs_right_content{
        padding: 50px 0;
      }
      .tabs{
        padding: 0 33px;
      }
      .screen_banner{
        margin-right: 20px;
      }
      .template_content{
        padding-bottom: 40px;
      }
      .templates_portfolio{
        flex-wrap: nowrap;
      }
      .temp{
        width: auto;
        height: auto;
      }
      .services{
        margin: 70px 0 40px 0;
      }
      .contentpage .services{
        margin: 0px 0 40px 0;
      }
      .service{
        flex-direction: column;
        margin-bottom: 20px;
      }
      .service_icon{
        margin-bottom: 20px;
        padding: 0;
      }
      .footer{
        padding: 50px 33px;
      }
      
}
@media only screen and (min-width:1140px) {
  .header{
    padding: 10px 94px;
      }
  .footer{
    padding: 80px 94px;
  }
  .tabs{
    padding: 0 94px;
  }
  .about{
    padding:0px 94px;
  }
  .menu ul li{
    padding: 0 10px;
  }
  
}
@media only screen and (max-width:1024px) {
/* .package_wrap{
  flex-wrap: wrap;
}
.package {
  width: 45%;
  margin: 5px;
} */

.packages_main{
  padding: 50px 33px;
}
}




